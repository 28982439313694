import React, { Component } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { GiTeacher, GiPublicSpeaker } from "react-icons/gi";
import Funny from "images/funny-pic.jpg";

export default class Footer extends Component {
  render() {
    const today = new Date();

    return (
      <>
        <div className="footer1">
          <div className="footer-column-wrapper">
            <div className="footer-column first rb">
              <div className="image">
                <img src={Funny} alt="Trinity Christiana with microphone" />
              </div>
              <div className="content">
                <h3 className="footer-header">{`I stack code blocks to architect software pushes to Master(pieces)`}</h3>
                {/* <h3 className="footer-header">{`["Live", "Laugh", "Learn", "Code"]`}</h3> */}
                <p>
                  As a recent college graduate and musician, I have enjoyed the
                  relationships I have made through being able to use my project
                  management, organization, and technical skills to achieve
                  goals.
                </p>
                <p>
                  I am looking for a position in tech that would allow me to
                  foster community and be a part of a team.
                </p>
              </div>
            </div>
            <div className="footer-column">
              <h3 className="footer-header">
                <FaInfoCircle /> More Info
              </h3>
              <ul>
                <li>
                  <a href="/portfolio">My Work</a>
                </li>
                <li>
                  <a
                    href="https://www.github.com/TrinityChristiana"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    GitHub
                  </a>
                </li>

                <li>
                  <a
                    href="https://github.com/TrinityChristiana/personal-site/blob/master/README.md"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About this App
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <p>
                Want to learn more about how we can collaborate on your next
                project or just grab a cup of coffee? Let's do this!
              </p>

              <p>
                <a className="btn btn-info" href="/contact">
                  Contact Me
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* <div className="footer2">
          <div className="footer-column-wrapper">
            <div className="footer-blocks rb">
              
              <div className="icon"><MdWork /></div> 
              <div className="content">
                <h4>Collaboration</h4>
                Working together to help your organization meet the needs of your users.
              </div>
            </div>

            <div className="footer-blocks rb">
            <div className="icon"><GiTeacher /></div> 
              <div className="content">
                <h4>Tutoring</h4>
                Learn with me! Let's work together to take your skills to the next level.
              </div>
            </div>

            <div className="footer-blocks">
              <div className="icon"><GiPublicSpeaker /></div> 
              <div className="content">
                <h4>Speaking</h4>
                With a vast background in strategy, design and development, I can make your next event amazing!
              </div>
            </div>
          </div>
        </div> */}

        <div className="footer3">
          <div className="footer-column-wrapper">
            <div className="footer-byline">
              &copy; 2000 - {today.getFullYear()}, Trinity Christiana. All
              Rights Reserved.
            </div>
            <div className="footer-navigation">
              <a className="link" href="/contact">
                Contact
              </a>
              {/* <a href="/research">Research</a> */}
              <a className="link" href="/resume">
                Experience
              </a>
              <a className="link" href="/">
                Home
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
