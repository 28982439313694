import React from "react";
import Menu from "./../components/Menu";
import Footer from "./../components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";

import "styles/main.scss";
import Helmet from "react-helmet";

export default ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="apple-mobile-web-app-title" content="Trinity Web" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="white" />
        <meta property="og:title" content="Trinity Christiana" />
        <meta property="og:image" content="images/trinity.jpg" />
        <meta
          property="og:description"
          content="The Future of
      Development: I stack code blocks to architect software pushes to Master(pieces)"
        />
        <meta property="og:url" content="http://trinitychristiana.com/" />
        <script type="text/javascript" src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>

        <title>Trinity Christiana | Full-Stack Software Engineer</title>
      </Helmet>
      <Menu />
      <div>{children}</div>
      <div className="push"></div>
      <Footer />
    </React.Fragment>
  );
};
