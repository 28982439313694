import React from "react";
import {
  FaGithub,
  FaLinkedin,
  FaFacebookSquare,
  FaTwitterSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import Logo from "images/trinitylogo.png";

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: "collapse",
    };
  }

  toggle() {
    if (this.state.isOpen === "collapse") {
      this.setState({
        isOpen: "",
      });
    } else {
      this.setState({
        isOpen: "collapse",
      });
    }
  }

  render() {
    return (
      <nav className="navbar sticky-top navbar-expand-lg navbar-dark primary-bg">
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="" />
        </a>
        <button
          onClick={this.toggle}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`${this.state.isOpen} navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav mr-auto"></ul>

          <span className="navbar-text">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link comma" href="/resume/">
                  Experience
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/portfolio/">
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about/">
                  About Me
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact/">
                  Contact
                </a>
              </li>
              <li className="nav-item line last">|</li>
              <div className="social-media">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://github.com/TrinityChristiana/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGithub />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://linkedin.com/in/trinitychristiana/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin />
                  </a>
                </li>
              </div>
            </ul>
          </span>
        </div>
      </nav>
    );
  }
}
